var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { visible: _vm.visible, footer: null },
      on: { cancel: _vm.close }
    },
    [
      _c(
        "span",
        {
          staticClass: "title text-black font-semi-bold",
          attrs: { slot: "title" },
          slot: "title"
        },
        [_vm._v(_vm._s(_vm.$t("Switch page")))]
      ),
      _c("ProjectPageSelect", {
        staticClass: "mb-sm",
        staticStyle: { width: "100%" },
        attrs: {
          value: _vm.page.id,
          data: _vm.filterProject,
          pageId: +_vm.$route.params.pageId
        },
        on: { select: _vm.onSelect }
      }),
      _c(
        "div",
        { staticClass: "content-end" },
        [
          _c("a-button", { staticClass: "mr-sm", on: { click: _vm.close } }, [
            _vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")
          ]),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.onSwitchPage } },
            [_vm._v(" " + _vm._s(_vm.$t("Switch")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }