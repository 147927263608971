//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    visible: {
      type: Boolean,
      required: true
    }
  },
  components: {
    ProjectPageSelect: () => import('@/components/tools/ProjectPageSelect.vue')
  },
  data() {
    return {
      page: {}
    };
  },
  computed: {
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pageById']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),

    defaultPage() {
      return this.pageById(this.currentPageId) || {};
    },
    currentPageId() {
      return this.$route.params.pageId;
    },
    filterProject() {
      // remove itself from projectsAndPages page list
      const projectsAndPages = this.projectsAndPages.map(project => {
        project.id = 'project' + project.id;
        // rename projects.projects to projects.children
        project.children = project.projects.filter(
          page => page.id !== +this.currentPageId && page.template !== 'PORTAL'
        );
        return project;
      });
      // filter out current project that include this page to be top of array
      const currentProjectIndex = projectsAndPages.findIndex(
        item => item.id === Number(this.projectId)
      );
      if (currentProjectIndex > -1) {
        projectsAndPages.unshift(projectsAndPages.splice(currentProjectIndex, 1)[0]);
      }

      return projectsAndPages;
    }
  },
  created() {
    this.getProjectsAndPagesByCompany(this.userInfo.company_id);
  },
  methods: {
    ...mapActions('project', ['getProjectsAndPagesByCompany']),
    onSwitchPage() {
      this.$emit('selected', this.page);
    },
    onSelect(page) {
      this.page = page;
    },
    close() {
      this.$emit('close');
    }
  }
};
